export const environment = {
    production: true,
    pbatID: 'PBAT709019',
    auth: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6IkFkbWluIiwiaWF0IjoxNjIwMTMxNTYxfQ.7iTT5-7gnQVrabIb4af5wMRWWfPbWQfIZXBWvL8wA-A",
    title: "MOYEE DOUBLE",

    wetmillLatitude: "8.105416196996657",
    wetmillLongitude: "36.98229328928205",
    roasterLatitude: "8.995912907282019",
    roasterLongitude: "38.784295132805994"
  };